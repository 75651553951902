<template>
  <div style="padding: 30px">
    <el-button type="primary" @click="addOrUpdateHandle()">添加轮播图</el-button>
    <el-table :data="tableData" border stripe style="width: 100%; margin-top: 20px">
      <el-table-column prop="name" label="名称" min-width="100"></el-table-column>
      <el-table-column label="图片" min-width="200">
        <template slot-scope="scope">
          <img :src="imgRealUrl(scope.row.image_url)" style="height: 50px"/>
        </template>
      </el-table-column>
      <el-table-column prop="url" label="跳转链接" min-width="200"></el-table-column>
      <el-table-column prop="sort" label="排序" min-width="80"></el-table-column>
      <el-table-column prop="is_publish" label="是否发布" min-width="80">
        <template slot-scope="scope">
          <template v-if="scope.row.is_publish==1">是</template>
          <template v-else >否</template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" plain @click="addOrUpdateHandle(scope.row.id)">编辑</el-button>
          <el-button size="mini" type="danger" plain @click="handleDelete(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 10px" background @size-change="handleSizeChange"
                   @current-change="handleCurrentChange" :current-page="tablePageInfo.currentPage"
                   :page-sizes="[10, 20, 50, 100]" :page-size="tablePageInfo.pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="tablePageInfo.total">
    </el-pagination>

    <detail v-if="detailVisible" ref="detail"  @refreshDataList="getTableDate"></detail>
  </div>
</template>

<script>
import Detail from "./detail";

export default {
  components: {
    Detail: Detail
  },

  data() {
    return {
      detailVisible:false,
      tableData: [],
      tablePageInfo: {
        pageSize: 10, //每页的条数
        total: 0, //总条数
        currentPage: 1, //当前页
      },
      dialogFormVisible: false,
    };
  },
  mounted () {
    this.getTableDate()
  },
  methods: {
    addOrUpdateHandle (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.dataForm.id = id
        this.$refs.detail.init()
      })
    },

    getTableDate: function (res) {
      let that = this;
      if (res != "") {
        // that.formSearch.page = 1;
      } //点击查询，恢复第一页
      this.$axios
          .post(this.baseUrl + "admin/Carouselctl/records", {})
          .then((response) => {
            if (response.data.code != "200") {
              that.$alert(response.data.msg, "提示", {
                confirmButtonText: "服务器异常，请联系管理员",
              });
            } else {
              that.tablePageInfo.total = response.data.data.count;
              that.tableData = response.data.data.res;
            }
          })
          .catch(function (error) {
            window.console.log(error);
          }); //ajax请求失败
    },

    handleDelete(scope) {
      let that = this;
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that.$axios.get(this.baseUrl + "admin/Carouselctl/delete?id=" + scope.row.id).then((response) => {
          if (response.data.code == "200") {
            that.getTableDate();
          }
          that.$alert(response.data.msg, "提示", {
            confirmButtonText: "确定"
          });
        }).catch(function (error) {
          window.console.log(error);
        }); //ajax请求失败
      }).catch(() => {
      })
    },
    imgRealUrl(image_url) {
      let url = this.baseUrl2  + image_url;
      return url;
    }, //图片真实地址

    handleSizeChange(val) {
      this.formSearch.limit = val;
      this.getTableDate("");
    }, //每页条数发生改变
    handleCurrentChange(val) {
      this.formSearch.page = val;
      this.getTableDate("");
    }, //翻页
  },
  watch: {
    watchvlue() {
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-icon-plus {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
</style>
