<template>
  <el-dialog :visible.sync="visible" :title="!dataForm.id ? '编辑' : '新增'" :close-on-click-modal="false"
             :close-on-press-escape="false">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmitHandle()"
             label-width="120px">
      <el-form-item prop="name" label="轮播图说明">
        <el-input v-model="dataForm.name"></el-input>
      </el-form-item>
      <el-form-item prop="url" label="跳转链接">
        <el-input v-model="dataForm.url"></el-input>
      </el-form-item>
      <el-form-item prop="image_url" label="图片">
        <el-upload class="avatar-uploader" :action="pictureUpload" :show-file-list="false"
                   :auto-upload="true" :on-change="handleChange" :on-success="uploadSuccess">
          <img v-if="image_show_url" :src="image_show_url" class="avatar"/>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item prop="sort" label="排序">
        <el-input-number :min="1" :step="1" v-model="dataForm.sort"></el-input-number>
      </el-form-item>
      <el-form-item prop="sort" label="是否发布">
        <el-switch v-model="dataForm.is_publish" active-color="#13ce66" active-text="是"
                   inactive-text="否" :active-value="1" :inactive-value="0">
        </el-switch>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'carouselDetail',
  data() {
    return {
      visible: false,
      pictureUpload: this.baseUrl + "admin/Carouselctl/uploadFile", //上传图片接口
      image_show_url: undefined,
      dataForm: {
        id: '',
        name: '',
        is_publish: 0,
        sort: '',
        image_url: undefined,
        url: '',
      },
      dataRule: {
        name: [{required: true, message: '年龄不能为空', trigger: 'blur'}],
        image_url: [{required: true, message: "请上传图片", trigger: 'blur'}],
      }
    };
  },
  mounted() {
  },
  methods: {
    //初始化操作
    init() {
      let _this = this
      this.visible = true
      this.$nextTick(() => {
        _this.$refs['dataForm'].resetFields()
        if (_this.dataForm.id) {
          _this.getInfo()
        }
      })
    },

    //获取详情信息
    getInfo() {
      let _this = this
      this.$axios.get(this.baseUrl + 'admin/Carouselctl/detail?id=' + this.dataForm.id).then(response => {
        if (response.data.code != '200') {
          _this.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
        } else {
          _this.dataForm = response.data.data;
          if (_this.dataForm.image_url != null) {
            _this.image_show_url = this.baseUrl2 + _this.dataForm.image_url
          }
        }
      }).catch(function (error) {
        window.console.log(error)
      });
    },

    //提交
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$axios
              .post(this.baseUrl + "admin/Carouselctl/save", this.dataForm, {
                headers: {
                  "Content-Type": "multipart/form-data"
                },
              })
              .then((response) => {
                if (response.data.code == "200") {
                  this.visible = false
                  this.$emit('refreshDataList')
                }

              })
              .catch(function (error) {
                window.console.log(error);
              }); //ajax请求失败
        } else {
          return false;
        }
      });
    },

    //文件上传成果
    uploadSuccess(response) {
      this.dataForm.image_url = response.location
      this.image_show_url = this.baseUrl2 + response.location
      console.log(this.dataForm.image_url)
      console.log(this.image_show_url)
    },

    //选择图片，图片变化
    handleChange(event) {
      let typeValid = /^image\/(jpeg|png|jpg)$/.test(event.raw.type);
      if (!typeValid) {
        this.$message.error("上传头像图片只能是 jpeg|png|jpg 格式!");
        return false;
      }
      // this.image_show_url = this.baseUrl2 + this.dataForm.image_url
    },
    //预览图片
    preview(id) {
      let that = this;
      this.$axios.post(this.baseUrl + 'admin/article/searchArticle?newsId=' + id).then(response => {
        if (response.data.code != '200') {
          that.$alert(response.data.msg, '提示', {confirmButtonText: '确定'});
        } else {
          that.info = response.data.data.res[0];
        }
      }).catch(function (error) {
        window.console.log(error)
      });//ajax请求失败
    }
  }
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-icon-plus {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
</style>